<!-- =========================================================================================
    File Name: VxTimeline.vue
    Description: Timeline Component
    Component Name: VxTimeline
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <ul class="vx-timeline">
      <li v-for="item in data" :key="item.title">
          <div class="timeline-icon" :class="`bg-${item.color}`">
              <feather-icon :icon="item.icon" svgClasses="text-white stroke-current w-5 h-5" />
          </div>
          <div class="timeline-info">
              <p class="font-semibold">{{ item.title }}</p>
              <span v-if="!html" class="activity-desc">{{ item.desc }}</span>
              <div v-if="html" v-html-safe="item.desc"></div>
          </div>
          <small class="text-grey activity-e-time">{{ formatTime(item.time) }}</small>
      </li>
  </ul>
</template>

<script>

export default {
  name: 'vx-timeline',
  props: {
    data: {
      type: Array,
      required: true
    },
    html: {
      type: Boolean,
      default: false
    },
    timeFormat: {
      type: String,
      default: 'human'
    }
  },
  methods: {
    formatTime(time) {
      const thenEpoch = (new Date(time)).getTime()

      switch (this.timeFormat) {
      case 'literal':
        return this.$utils.format.dateWithFormat(time, 'DD/MM/YYYY - kk:mm:ss')
      default:
        return this.readableHumanTime(thenEpoch)
      }
      
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
</style>
